<template>
  <b-modal
    :id="`viewModal_${id}`"
    centered
    :size="size"
    @hide="close"
    hide-footer
  >
    <template #modal-title> {{ title }} </template>
    <div :disabled="loading">
      <slot></slot>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ViewModal',
  props: {
    id: {
      type: String,
      default: new Date().getTime().toString(),
    },
    show: Boolean,
    title: String,
    size: {
      type: String,
      default: 'md',
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    close() {
      this.$bvModal.hide(`viewModal_${this.id}`);
      this.$emit('closeView', false);
    },
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.$bvModal.show(`viewModal_${this.id}`);
      } else this.$bvModal.hide(`viewModal_${this.id}`);
    },
  },
};
</script>
